export const useHeadCommon = () => {
  const { y } = useWindowScroll()
  const { localeProperties } = useI18n()

  useHead({
    htmlAttrs: {
      lang: computed(() => localeProperties.value.iso),
    },
    bodyAttrs: {
      class: computed(() => {
        if (y.value) return 'is-scrolled'

        return ''
      }),
    },
  })
}
